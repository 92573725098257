<template>
  <div class="message" ref="appRef">
    <div class="bg">
      <MyHeader :title="title"></MyHeader>
      <div class="message-container">
        <div class="top-container">
          <div class="message-title">生产通知</div>
          <div class="table-legend">
            <div class="color-info">
              <div class="color-label">未处理</div>
              <div class="color-block-success"></div>
            </div>
            <div class="color-info">
              <div class="color-label">处理中</div>
              <div class="color-block-warning"></div>
            </div>
            <div class="color-info">
              <div class="color-label">已处理</div>
              <div class="color-block-danger"></div>
            </div>
          </div>
        </div>
        <div class="message-grid">
          <div v-for="(message,index) in messages" :key="index">
            <div class="message-item">
              <div style="background-color: #df5000">
                <i class="el-icon-chat-line-square"></i>
              </div>
              <div class="item" :style="{backgroundColor: message.color}">
                <div class="item-desc">
                  <div class="message-item-title">{{ message.title }}</div>
                  <div class="message-item-time">{{ message.sent_date }}</div>
                </div>
                <div class="item-content">
                  <div class="message-item-content">用户{{ message.user_id }}&nbsp;&nbsp;机床{{ message.machine_id }}号&nbsp;&nbsp;{{
                      message.content
                    }}
                  </div>
                  <i class="el-icon-warning" v-if="!message.is_read" @click="read(message)"> </i>
                </div>
                <!--                <div class="divider"></div>-->
              </div>

            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
              :hide-on-single-page="false"
              layout="prev, pager, next"
              :total="this.total_size"
              :page-size="this.page_size"
              @current-change="handleChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import message from "@/api/message";
// import mock from "@/api/mock";

export default defineComponent({
  name: "message",
  mixins: [drawMixin],
  data() {
    return {
      title: "消息通知",
      messages: [],
      current_page: 1,
      page_size: 8,
      total_size: 0,
    }
  },
  components: {
    MyHeader,
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
    // setInterval(() => {
    //   this.fetchTableData();
    // }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    getBCG(type) {
      if (type == 1) {
        return '#f8e5ec';
      } else if (type == 2) {
        return '#fffbe6';
      } else if (type == 3) {
        return '#f0f9eb';
      } else {
        return '#e7e3e3'
      }
    },
    handleChange(val) {
      this.current_page = val;
      this.fetchData();
    },
    read(item) {
      item.is_read=1;
      message.updateMessageIsRead(item).then(response => {
        console.log(response);
      })
    },
    fetchData() {
      message.getMessageAnalysis(this.page_size, this.current_page).then(response => {
        console.log(response);
        this.total_size = response.data.data.total;
        this.messages = response.data.data.item;
        this.messages.sort((a, b) => new Date(b.sent_date) - new Date(a.sent_date));
        this.messages.forEach(message => {
          const dateObject = new Date(message.sent_date);
          message.sent_date = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
          message.color = this.getBCG(message.type);
          console.log(message.color, '---color---', message.type);
        });
      })
    },
  }
})
</script>

<style scoped lang="scss">
@import '../../assets/scss/message.scss';
</style>
