import http from '../utils/request.js'


const message = {
    getMessageAnalysis(limit, start) {
        // if (!id) id = 1
        return http.get(`/message/messages?limit=${limit}&start=${start}`)
    },
    updateMessageIsRead(item){
        const params = {
            is_read: 1,
        }
        return http.put(`/message/${item.message_id}`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    }
}

export default message;
